<template>
  <v-expansion-panel class="space">
    <v-expansion-panel-header>
      <div class="d-flex flex-column flex-md-row align-start align-md-center justify-space-between">
        <!-- Nome e cargo do colaborador -->
        <div v-if="item && item.colaborador" class="d-flex flex-column flex-sm-row align-start align-sm-center">
          <v-img class="border-radius-full-custom" alt="sjdsbddb" max-height="80" max-width="80"
            :src="item.colaborador.foto"></v-img>

          <div class="ml-4 my-2 d-flex flex-column align-start">
            <h3 class="color-legiio">{{ item.colaborador.nome }}</h3>
            <h5 class="grey--text lighten-1">
              {{ item.colaborador.cargo.nome }}
            </h5>
          </div>
        </div>
        <h3 v-else class="color-legiio">Avaliação</h3>

        <!-- Gestor e Status -->
        <div class="d-flex flex-row flex-wrap align-start justify-space-between ma-2">
          <div v-if="item && item.colaborador && item.responsaveis" class="d-flex flex-column justify-start my-1 mr-4">
            <h6 class="subtitle-2 grey--text lighten-1 text-left font-weight-light">
              gestor(a)
            </h6>
            <h4 class="grey--text lighten-1 font-weight-bold">
              {{ item.responsaveis.nome }}
            </h4>
          </div>
          <div class="d-flex align-center justify-end ma-1">
            <v-chip label class="font-weight-medium" :color="status && status[item.status].color
                ? status[item.status].color
                : ''
              ">{{
    status && status[item.status].text
    ? status[item.status].text
    : ""
  }}</v-chip>
          </div>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="d-flex flex-column">
        <!-- Tipos de avaliação -->
        <div class="d-flex flex-column align-start">
          <h5 class="grey--text lighten-1">Tipos de avaliação</h5>
          <v-chip-group column multiple>
            <v-chip v-for="(tipoItem, i) in item.tipos" :key="i" label outlined color="#212121" text-color="#162C40"
              class="font-weight-bold">{{ tipos[tipoItem].nome }}</v-chip>
          </v-chip-group>
        </div>
        <div class="d-flex flex-column flex-md-row align-start justify-space-between">
          <!-- Datas -->
          <div class="grey lighten-4 d-flex flex-row flex-wrap">
            <!-- Data inicial -->
            <div class="d-flex flex-column ma-2">
              <h6 class="grey--text darken-4 font-weight-light">
                Data inicial
              </h6>
              <h5 class="grey--text darken-4 font-weight-bold">
                {{ item.data_inicio | dateFormatBr() }}
                {{ item.hora_inicio | timeFormatBr() }}h
              </h5>
            </div>

            <!-- Data finalizada -->
            <div class="d-flex flex-column ma-2">
              <h6 class="grey--text darken-4 font-weight-light">
                Data finalizada
              </h6>
              <h5 class="grey--text darken-4 font-weight-bold">
                {{ item.data_final | dateFormatBr() }}
                {{ item.hora_final | timeFormatBr() }}h
              </h5>
            </div>

            <!-- Data da ultima atualização -->
            <div class="d-flex flex-column ma-2">
              <h6 class="grey--text darken-4 font-weight-light">
                Data da última atualização
              </h6>
              <h5 class="grey--text darken-4 font-weight-bold">
                {{ item.data_ultima_atualizacao | dateFormatBr() }}
                {{ item.data_ultima_atualizacao | timeFormatBr() }}h
              </h5>
            </div>
          </div>

          <v-btn v-if="item && item.status == 'C'" class="px-2 py-6 my-2" color="#162c40" dark
            @click="$emit('ver-avaliacao', item)">Ver detalhes</v-btn>
          <v-btn v-else-if="item && item.colaborador && item.status != 'C'" :disabled="true" class="px-2 py-6 my-2"
            color="#162c40" @click="$emit('ver-avaliacao', item)">Ver detalhes</v-btn>
          <v-btn v-else-if="item && item.status == 'A' && !item.colaborador" :disabled="true"
            class="px-2 py-6 my-2 grey--text darken-2" color="#D0D0D0"><v-icon>mdi-play</v-icon> Iniciar avaliação</v-btn>
          <v-btn v-else-if="item && item.status == 'E' && !item.colaborador" class="px-2 py-6 my-2" color="#18A4B7" dark
            @click="abrirAvaliacao(item.uuid)"><v-icon>mdi-play</v-icon> Continuar avaliação</v-btn>
          <v-btn v-else class="px-2 py-6 my-2" color="#18B744" dark
            @click="abrirAvaliacao(item.uuid)"><v-icon>mdi-play</v-icon> Iniciar avaliação</v-btn>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "CardAvaliacaoComponent",

  props: ["item"],

  computed: {
    status: {
      get() {
        return this.$store.getters["avd/getStatusAvaliacao"];
      },
    },
    tipos: {
      get() {
        return this.$store.getters["avd/getTiposAvaliacao"];
      },
    },
  },

  methods: {
    abrirAvaliacao(uuid = String) {
      console.log("Iniciei avaliacao: ", uuid);
      // Aqui terá a rota que leva para realizar a avaliação
    },
  },
};
</script>

<style scoped>
.space {
  margin: 0.5em;
}

.color-legiio {
  color: #162c40;
}

.border-radius-full-custom {
  border-radius: 50%;
}
</style>
