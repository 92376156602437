<template>
  <v-dialog v-model="openDialog">
    <!-- Cabeçalho -->
    <div class="white">
      <v-card
        class="pa-4"
        elevation="0"
        style="background-color: #eff2fa !important"
      >
        <div
          v-if="
            (permissaoDoUsuario.role == 'admin' && item && item.colaborador) ||
            (permissaoDoUsuario.role == 'gestor' && item && item.colaborador) ||
            (permissaoDoUsuario.role == 'recursos-humanos' &&
              item &&
              item.colaborador)
          "
          class="d-flex flex-column flex-sm-row align-start align-sm-center"
        >
          <v-img
            class="border-radius-full-custom"
            alt="sjdsbddb"
            max-height="60"
            max-width="60"
            :src="item.colaborador.foto"
          ></v-img>

          <div class="ml-4 my-2 d-flex flex-column align-start">
            <h4 class="color-legiio">
              {{ item && item.colaborador.nome ? item.colaborador.nome : "" }}
            </h4>
            <h6 class="grey--text lighten-1">
              Cargo:
              {{
                item && item.colaborador.cargo.nome
                  ? item.colaborador.cargo.nome
                  : ""
              }}
            </h6>
            <h6 class="grey--text lighten-1">
              Setor:
              {{
                item && item.colaborador.setor.nome
                  ? item.colaborador.setor.nome
                  : ""
              }}
            </h6>
          </div>
        </div>

        <div v-else class="d-flex flex-column">
          <h4 class="text-left">{{ item && item.nome ? item.nome : "" }}</h4>
          <p class="pa-0 ma-0 body-2 text-justify my-2">
            {{ item && item.descricao ? item.descricao : "" }}
          </p>

          <!-- gestor -->
          <div class="my-2 d-flex flex-row align-center">
            <v-icon class="mr-2">mdi-account-tie</v-icon>
            <p class="pa-0 ma-0 body-1">
              {{ item && item.responsaveis.nome ? item.responsaveis.nome : "" }}
            </p>
          </div>
        </div>
      </v-card>

      <!-- Corpo -->
      <v-card
        style="background-color: white !important"
        class="mt-4 mx-2"
        elevation="0"
      >
        <!-- Tem que acrescentar um Disabled caso não haja o nome da avaliação  -->
        <v-tabs show-arrows class="border-bottom" v-model="tabs">
          <v-tabs-slider color="#162C40"></v-tabs-slider>
          <v-tab
            v-for="tab in tabsVisualizarAvaliacao"
            :key="tab.id"
            :href="`#tab-${tab.id}`"
            class="primary--text"
          >
            <span class="color-legiio">{{ tab.nome }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item
            class="ma-4"
            v-for="tabItem in tabsVisualizarAvaliacao"
            :key="tabItem.id + 1"
            :value="'tab-' + tabItem.id"
          >
            <component :is="tabItem.componente"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <v-card style="background-color: #eff2fa !important" elevation="0">
        <div class="d-flex flex-row align-end justify-end">
          <v-btn
            @click="$emit('close-dialog')"
            elevation="0"
            class="ma-4 grey lighten-2 color-legiio"
            >Fechar</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "CardVisualizarAvaliacaoComponent",

  props: ["openDialog", "item"],

  data() {
    return {
      tabs: null,
    };
  },

  computed: {
    permissaoDoUsuario: {
      get() {
        return this.$store.getters[
          "avd/getPermissaoDoUsuario"
        ];
      },
    },
    tabsVisualizarAvaliacao: {
      get() {
        return this.$store.getters[
          "avd/getTabsVisualizarAvaliacao"
        ];
      },
    },
  },
};
</script>

<style scoped>
.color-legiio {
  color: #162c40;
}

.border-radius-full-custom {
  border-radius: 50%;
}
</style>
