<template>
  <v-card rounded="5" elevation="0" color="#EFF2FA">
    <div
      class="d-flex flex-column flex-md-row align-center justify-space-between mx-6 mt-4 mb-6"
    >
      <h3
        class="color-legiio d-flex align-center subtitle-1 font-weight-bold cursor-default"
      >
        <v-icon class="mr-2" color="#162c40">mdi-alert-outline</v-icon>
        Avaliações pendentes ({{
          items && items.length > 0 ? items.length : "0"
        }})
      </h3>

      <div class="d-flex flex-row align-center">
        <v-btn @click="ordenarResponsavel()" class="ma-2 subtitle-2" plain
          >Responsável <v-icon>mdi-menu-swap</v-icon></v-btn
        >
        <v-btn @click="ordenarStatus()" class="ma-2 subtitle-2" plain
          >Status <v-icon>mdi-menu-swap</v-icon></v-btn
        >
      </div>
    </div>

    <v-card elevation="0" max-height="500" class="ma-4 pa-4 overflow-y-auto">
      <v-expansion-panels>
        <CardAvaliacaoComponent
          v-for="item in items"
          :key="item.id"
          :item="item"
          @ver-avaliacao="abrirModalVisualizarAvaliacao($event)"
        />
      </v-expansion-panels>
    </v-card>

    <CardVisualizarAvaliacaoComponent
      :item="visualizarAvaliacao"
      :openDialog="dialog"
      @close-dialog="dialog = false"
    />
  </v-card>
</template>

<script>
import CardAvaliacaoComponent from "./CardAvaliacaoComponent.vue";
import CardVisualizarAvaliacaoComponent from "./CardVisualizarAvaliacaoComponent";

export default {
  name: "EstruturaAvaliacaoComponent",
  components: {
    CardAvaliacaoComponent,
    CardVisualizarAvaliacaoComponent,
  },

  props: ["items"],

  data() {
    return {
      ondemResponsavelAtual: true,
      ondemStatusAtual: true,

      dialog: false,
      visualizarAvaliacao: null,
    };
  },

  methods: {
    ordenarResponsavel() {
      this.items.sort((itemA, itemB) => {
        if (this.ondemResponsavelAtual) {
          if (itemA.responsaveis.nome > itemB.responsaveis.nome) {
            return 1;
          } else if (itemA.responsaveis.nome < itemB.responsaveis.nome) {
            return -1;
          }
        } else if (!this.ondemResponsavelAtual) {
          if (itemA.responsaveis.nome < itemB.responsaveis.nome) {
            return 1;
          } else if (itemA.responsaveis.nome > itemB.responsaveis.nome) {
            return -1;
          }
        }

        return 0;
      });

      this.ondemResponsavelAtual = !this.ondemResponsavelAtual;
    },
    ordenarStatus() {
      this.items.sort((itemA, itemB) => {
        if (this.ondemStatusAtual) {
          if (itemA.status > itemB.status) {
            return 1;
          } else if (itemA.status < itemB.status) {
            return -1;
          }
        } else if (!this.ondemStatusAtual) {
          if (itemA.status < itemB.status) {
            return 1;
          } else if (itemA.status > itemB.status) {
            return -1;
          }
        }

        return 0;
      });

      this.ondemStatusAtual = !this.ondemStatusAtual;
    },
    abrirModalVisualizarAvaliacao(item) {
      this.$store.dispatch(
        "avd/buscarVisualizarAvaliacao",
        item.uuid
      );

      this.dialog = true;
      this.visualizarAvaliacao = item;
    },
  },
};
</script>

<style scoped>
.color-legiio {
  color: #162c40;
}

.cursor-default {
  cursor: default;
}
</style>
